export default (el, binding)=>{
    const getData = binding.instance[binding.value.fn] ? binding.instance[binding.value.fn] : binding.value.fn;
    el.addEventListener("click", function( event ){
        const target=event.target;
        if( !target.dataset["sort"] ) return;
        let sort_type = "";
        if(target.classList.value === "desc"){
            sort_type = "asc";
        }else if(target.classList.value === "asc"){
            sort_type = "";
        }else{
            sort_type = "desc";
        }
        getData(null, 1, sort_type ? target.dataset["sort"] + "," + sort_type : sort_type);
    });
}