import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Helper from '@/Helper.js'

import CoreuiVue from '@coreui/vue'
import CIcon from '@coreui/icons-vue'
import { iconsSet as icons } from '@/assets/icons'
import DocsCallout from '@/components/DocsCallout'
import DocsExample from '@/components/DocsExample'
import BootstrapVue3 from 'bootstrap-vue-3'
import{ createPinia }from"pinia";
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

import Datepicker from 'vue-datepicker-next'
import 'vue-datepicker-next/index.css'
import 'vue-datepicker-next/locale/ko'

import Multiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

import CKEditor from '@ckeditor/ckeditor5-vue'
import TableSort from "@/TableSort.js";

const app = createApp(App)

function userDevice() {
	const user = navigator.userAgent;
	let mobile = false;
	if (
		user.indexOf("iPhone") > -1 ||
		user.indexOf("Android") > -1 ||
		user.indexOf("iPad") > -1 ||
		user.indexOf("iPod") > -1
	) {
		mobile = true;
	}
	return mobile;
}

const mobile_device = userDevice();

if ( mobile_device ) {
	document.getElementsByTagName("body")[0].classList.add("mobile");
} else {
	document.getElementsByTagName("body")[0].classList.add("desktop");
}

app.directive("table-sort", {
    created(el, binding){ TableSort(el, binding) }
});

app.directive("phone-hyphen", {
    mounted( el ){
        el.addEventListener("input", ( event )=>{const target=event.target;
            target.value = target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        });
        el.addEventListener("blur", ( event )=>{const target=event.target;
            target.value = target.value.replace(/ /g, "");
            target.value = Helper.phoneDashInsert( target.value );
        });
        el.addEventListener("focus", ( event )=>{const target=event.target;
            target.value = target.value.replace(/-/g, "");
        });
    }
});

app.use(store)
app.use(router)
app.use(CoreuiVue)
app.provide('icons', icons)
app.component('CIcon', CIcon)
app.component('DocsCallout', DocsCallout)
app.component('DocsExample', DocsExample)
app.component('ckeditor', CKEditor.component)
app.use(BootstrapVue3)
app.use( createPinia() );

app.mount('#app')

// component
app.component('Datepicker', Datepicker)
app.component('Multiselect', Multiselect)

// prototype
app.config.globalProperties.$Helper = Helper
app.config.globalProperties.$size = 15
