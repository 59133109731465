import { h, resolveComponent } from 'vue'
import {createRouter, createWebHistory} from 'vue-router'
import store from '@/store/index'

import DefaultLayout from '@/layouts/DefaultLayout'

const registerAuth = () => (to, from, next) => {
	if (store.state.userId) {
		return next()
	}
	next('/check-email')
}

const checkEmailAuth = () => (to, from, next) => {
	if (!store.state.userId) {
		return next()
	}
	next('/register')
}

const routes = [
	{
		path: '/',
		name: 'Home',
		component: DefaultLayout,
		redirect: '/login',
		children: [
			{
				path: '/order',
				name: 'Order',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/order/list',
				children: [
					{
						"path" : "/order/list",
						"name" : "OrderList",
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataOrderClear"
						},
						"component" : ()=>import("@/views/order/OrderList.vue"),
					},
					{
						path: '/order/list/:id',
						name: 'OrderDeail',
						component: () => import('@/views/order/OrderDetail.vue'),
					},
					{
						path: '/order/return',
						name: 'OrderReturn',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataReturnClear"
						},
						component: () => import('@/views/order/OrderReturn.vue'),
					},
					{
						path: '/order/return/:id',
						name: 'OrderReturnDeail',
						component: () => import('@/views/order/OrderDetail.vue'),
					},
					{
						path: '/order/self',
						name: 'OrderSelf',
						component: () => import('@/views/order/OrderSelf.vue'),
					},
				],
			},
			{
				path: '/deal',
				name: 'Deal',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/deal/list',
				children: [
					{
						path: '/deal/list',
						name: 'DealList',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataDealClear"
						},
						component: () => import('@/views/deal/DealList.vue'),
					},
					{
						path: '/deal/list/:id',
						name: 'DealDeail',
						component: () => import('@/views/deal/DealDetail.vue'),
					},
					{
						path: '/deal/clclt/',
						name: 'DealClclt',
						component: () => import('@/views/deal/DealClclt.vue'),
					},
				],
			},
			{
				path: '/product',
				name: 'Product',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/product/list',
				children: [
					{
						path: '/product/list',
						name: 'ProductList',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataProductClear"
						},
						component: () => import('@/views/product/ProductList.vue'),
					},
					{
						path: '/product/create',
						name: 'ProductCreate',
						component: () => import('@/views/product/ProductCreate.vue'),
					},
					{
						"path": "/product/create_related",
						"name": "RelatedProduct",
						"component": ()=>import("@/views/product/RelatedProduct.vue"),
					},
					{
						path: '/product/list/:id',
						name: 'ProductEdit',
						component: () => import('@/views/product/ProductEdit.vue'),
					},
					{
						"path": "/product/list/related/:id",
						"name": "RelatedProductEdit",
						"component": ()=>import("@/views/product/RelatedProduct.vue"),
					},
				],
			},
			{
				path: '/stock',
				name: 'Stock',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/stock/list',
				children: [
					{
						path: '/stock/list',
						name: 'StockList',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataStockClear"
						},
						component: () => import('@/views/stock/StockList.vue'),
					},
					{
						path: '/stock/list/:id',
						name: 'StockDetail',
						component: () => import('@/views/stock/StockDetail.vue'),
					},
				],
			},
			{
				path: '/store',
				name: 'Store',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/store/list',
				children: [
					{
						path: '/store/list',
						name: 'StoreList',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataStoreClear"
						},
						component: () => import('@/views/store/StoreList.vue'),
					},
					{
						path: '/store/list/:id',
						name: 'StoreDetail',
						component: () => import('@/views/store/StoreDetail.vue'),
					},
					{
						path: '/store/adjustment',
						name: 'Adjustment',
						component: () => import('@/views/store/Adjustment.vue'),
					},
					/*{	//2025.01.02 제거
						path: '/store/month_sales_ledger',
						name: 'MonthSalesLedger',
						component: () => import('@/views/store/MonthSalesLedger.vue'),
					}*/
				],
			},
			{
				path: '/chat',
				name: 'Chat',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/chat/list',
				children: [
					{
						path: '/chat/list',
						name: 'ChattingList',
						component: () => import('@/views/chat/ChattingList.vue'),
					},{
						path: '/chat/detail',
						name: 'ChattingDetail',
						component: () => import('@/views/chat/ChattingDetail.vue'),
					},
				],
			},
			{
				path: '/tax',
				name: 'Tax',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/tax/list',
				children: [
					{
						path: '/tax/create',
						name: 'TaxCreate',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataTaxCreateClear"
						},
						component: () => import('@/views/tax/TaxCreate.vue'),
					},
					{
						path: '/tax/send',
						name: 'TaxSend',
						"meta" : {
							"table_sort" : true,
							"clear_fn" : "searchDataTaxSendClear"
						},
						component: () => import('@/views/tax/TaxSend.vue'),
					},
				],
			},
			{
				path: '/account',
				name: 'Account',
				component: {
					render() {
						return h(resolveComponent('router-view'))
					},
				},
				redirect: '/account/channel',
				children: [
					{
						path: '/account/channel',
						name: 'accountChannel',
						component: () => import('@/views/account/AccountChannel.vue'),
					},
					{
						path: '/account/manager',
						name: 'accountManager',
						component: () => import('@/views/account/AccountManager.vue'),
					},
					{
						path: '/account/info',
						name: 'accountInfo',
						component: () => import('@/views/account/AccountInfo.vue'),
					},
				],
			},
		],
	},
	{
		path: '/login',
		name: 'Login',
		component: () => import('@/views/user/Login'),
	},
	{
		path: '/register',
		name: 'Register',
		component: () => import('@/views/user/Register'),
		beforeEnter: registerAuth(),
	},
	{
		path: '/check-email',
		name: 'CheckEmail',
		component: () => import('@/views/user/CheckEmail'),
		beforeEnter: checkEmailAuth(),
	},
	{
		path: '/change-email/:id',
		name: 'ChangePwd',
		component: () => import('@/views/user/ChangePwd'),
	},
]

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 }
	},
})

router.beforeEach((to, from)=>{
	if( !to.meta.table_sort ) return;
	if(from.path.indexOf(to.path) === -1){
		store.commit( to.meta.clear_fn );
	}
});
export default router;
